import React, { useState } from 'react';
import "./FormulaireClient.css";
import { useTranslation } from 'react-i18next';

function FormulaireClient() {
    const [userType, setUserType] = useState("particulier");
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const { t } = useTranslation();


    const handleCheckboxChange = () => {
        setAcceptedTerms(!acceptedTerms);
    };
    const initialFormData = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        subject: '',
        message: '',
        acceptTerms: false,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [formStatus, setFormStatus] = useState(null); // To track the form submission status

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.staging.k8s.isypay.net/api/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Set form status to success
                setFormStatus('success');
                // Reset form data
                setFormData(initialFormData);
            } else {
                // Set form status to error
                setFormStatus('error');
            }
        } catch (error) {
            // Set form status to error
            setFormStatus('error');
            console.error('Form submission error', error);
        }
    };


    return (
        <div className='form-client'>
            <div className="form-container-client">
                <div className="form-title-client">{t('FormulaireClient.title')}</div>
            </div>
            <div className="card-Form-client">
                <div className="card-body-Form-client">
                    <form onSubmit={handleSubmit}>
                        <div className="coolinput-client">
                            <label htmlFor="firstName" className="text">{t('FormulaireClient.firstName')}</label>
                            <input type="text" id="firstName" name="firstName" className="input" value={formData.firstName} onChange={handleChange} />
                        </div>
                        <div className="coolinput-client">
                            <label htmlFor="lastName" className="text">{t('FormulaireClient.lastName')}</label>
                            <input type="text" id="lastName" name="lastName" className="input" value={formData.lastName} onChange={handleChange} />
                        </div>
                        <div className="coolinput-client">
                            <label htmlFor="phoneNumber" className="text">{t('FormulaireClient.phoneNumber')}</label>
                            <input type="text" id="phoneNumber" name="phoneNumber" className="input" value={formData.phoneNumber} onChange={handleChange}/>
                        </div>
                        <div className="coolinput-client">
                            <label htmlFor="email" className="text">{t('FormulaireClient.email')}</label>
                            <input type="text" id="email" name="email" className="input" value={formData.email} onChange={handleChange}/>
                        </div>
                        <div className="coolinput-client">
                            <label htmlFor="subject" className="text">{t('FormulaireClient.subject')}</label>
                            <input type="text" id="subject" name="subject" className="input" value={formData.subject} onChange={handleChange}/>
                        </div>
                        <div className="coolinput-client">
                            <label htmlFor="message" className="text">{t('FormulaireClient.message')}</label>
                            <textarea id="message" name="message" className="input" rows={5} cols={50} value={formData.message} onChange={handleChange} />
                        </div>
                        <div className="coolinput-client">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    name="acceptTerms"
                                    checked={acceptedTerms}
                                    onChange={handleCheckboxChange}
                                />
                                {t('FormulaireClient.acceptTerms')}
                            </label>
                        </div>
                        <div className="coolinput-client">
                            <button type="submit" className="envoyer-button">{t('FormulaireClient.button')}</button>
                        </div>
                    </form>
                </div>
            </div>
            {formStatus === 'success' && <p>Form submitted successfully!</p>}
            {formStatus === 'error' && <p>There was an error submitting the form. Please try again.</p>}
        </div>
    );
}

export default FormulaireClient;
