import React, { useState } from 'react';
import "./RequestDataDeletion.css";
import { useTranslation } from 'react-i18next';

function RequestDataDeletion() {
    const [formData, setFormData] = useState({ email: '', phone: '', country: '', reason: '', otherReason: '' });
    const [formStatus, setFormStatus] = useState(null);
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.staging.k8s.isypay.net/api/request-data-deletion', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormStatus('success');
                setFormData({ email: '', phone: '', country: '', reason: '', otherReason: '' });
            } else {
                setFormStatus('error');
            }
        } catch (error) {
            setFormStatus('error');
            console.error('Form submission error', error);
        }
    };

    return (
        <div className='request-data-deletion' >
            <div className="form-container">
                <div className="form-title">{t('RequestDataDeletion.title')}</div>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email" className="text">{t('RequestDataDeletion.email')}</label>
                        <input type="email" id="email" name="email" className="input" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="phone" className="text">{t('RequestDataDeletion.phone')}</label>
                        <input type="tel" id="phone" name="phone" className="input" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="country" className="text">{t('RequestDataDeletion.country')}</label>
                        <input type="text" id="country" name="country" className="input" value={formData.country} onChange={handleChange} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="reason" className="text">{t('RequestDataDeletion.reason')}</label>
                        <select id="reason" name="reason" className="input" value={formData.reason} onChange={handleChange} required>
                            <option value="">{t('RequestDataDeletion.selectReason')}</option>
                            <option value="privacy">{t('RequestDataDeletion.privacy')}</option>
                            <option value="service">{t('RequestDataDeletion.service')}</option>
                            <option value="other">{t('RequestDataDeletion.other')}</option>
                        </select>
                    </div>
                    {formData.reason === 'other' && (
                        <div className="input-group">
                            <label htmlFor="otherReason" className="text">{t('RequestDataDeletion.otherReason')}</label>
                            <textarea id="otherReason" name="otherReason" className="input" rows={5} value={formData.otherReason} onChange={handleChange} required />
                        </div>
                    )}
                    <div className="input-group">
                        <button type="submit" className="submit-button">{t('RequestDataDeletion.submit')}</button>
                    </div>
                </form>
                {formStatus === 'success' && <p>{t('RequestDataDeletion.successMessage')}</p>}
                {formStatus === 'error' && <p>{t('RequestDataDeletion.errorMessage')}</p>}
            </div>
        </div>
    );
}

export default RequestDataDeletion;