import React from 'react';
import "./PrivacyPolicy.css";
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <h1>{t('PrivacyPolicy.title')}</h1>
      <div className="section">
        <h2>{t('PrivacyPolicy.section1.title')}</h2>
        <p>{t('PrivacyPolicy.section1.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section2.title')}</h2>
        <h3>{t('PrivacyPolicy.section2.subsection1.title')}</h3>
        <p>{t('PrivacyPolicy.section2.subsection1.text')}</p>
        <h3>{t('PrivacyPolicy.section2.subsection2.title')}</h3>
        <p>{t('PrivacyPolicy.section2.subsection2.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section3.title')}</h2>
        <p>{t('PrivacyPolicy.section3.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section4.title')}</h2>
        <p>{t('PrivacyPolicy.section4.text1')}</p>
        <p>{t('PrivacyPolicy.section4.text2')}</p>
        <p>{t('PrivacyPolicy.section4.text3')}</p>
        <p>{t('PrivacyPolicy.section4.text4')}</p>
        <p>{t('PrivacyPolicy.section4.text5')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section5.title')}</h2>
        <p>{t('PrivacyPolicy.section5.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section6.title')}</h2>
        <p>{t('PrivacyPolicy.section6.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section7.title')}</h2>
        <p>{t('PrivacyPolicy.section7.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section8.title')}</h2>
        <p>{t('PrivacyPolicy.section8.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section9.title')}</h2>
        <p>{t('PrivacyPolicy.section9.text')}</p>
      </div>
      <div className="section">
        <h2>{t('PrivacyPolicy.section10.title')}</h2>
        <p>{t('PrivacyPolicy.section10.text')}</p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;